
.AppLogo {
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
    user-select: none;

    padding: 0 0.5rem;
    font-weight: 500;

    text-transform: uppercase;

    font-size: 2rem;
    color: whitesmoke;
}
