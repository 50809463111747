

.AppSidebar {
    min-width: 300px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding-top: 40px;
    padding-left: 30px;
    padding-right: 60px;
    gap: 10px;

    background-color: var(--surface-500);

    user-select: none;
}
