

.LangSelection-Header {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 1rem;
    min-height: 3.5rem;
    color: whitesmoke;

    transition: background-color 450ms;
    &:hover {
        transition: background-color 450ms;
        background-color: var(--surface-900);
    }
}

.LangSelection-Body {
    display: flex;
    flex-direction: column;
    min-width: 200px;

    color: whitesmoke;
    background-color: var(--surface-700);
}