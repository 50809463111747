
.LangButton {
    display: grid;
    grid-template-columns: 60px auto;
    gap: 1rem;

    padding: 0.75rem 1rem;
    cursor: pointer;
    user-select: none;

    align-items: center;

    img {
        justify-self: center;
        width: auto;
        height: 1.5em;
    }

    &:hover {
        background-color: var(--surface-900);
    }
}
