

.UserAccountMenu {
}

.UserAccountMenu-Header {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 1rem;
    min-height: 3.5rem;
    color: whitesmoke;

    transition: background-color 450ms;
    &:hover {
        transition: background-color 450ms;
        background-color: var(--surface-900);
    }
}

.UserAccountMenu-PopupMenu {
    display: flex;
    flex-direction: column;
    min-width: 200px;

    color: whitesmoke;
    background-color: var(--surface-700);
}

.UserAccountMenu-MenuItem {
    display: grid;

    color: whitesmoke;
    white-space: nowrap;

    padding: 0.85rem 1.75rem;
    cursor: pointer;
    user-select: none;

    align-items: center;

    &:hover {
        background-color: var(--surface-900);
    }
}
