

.AutoOverlay {
    position: relative;
    display: grid;
}

.AutoOverlay-Header {
    user-select: none;
    cursor: pointer;
}

.AutoOverlay-Popup {
    position: absolute;
    right: 0;
    top: var(--header-height, 0);
    height: 0;
    opacity: 0;
    z-index: 1;

    overflow: hidden;

    width: auto;

    transition: height 250ms, opacity 250ms;

    &.AutoOverlay-Popup--Show {
        opacity: 1;
        height: var(--popup-height, 0);
        transition: opacity 350ms, height 350ms;
    }
}
