
.Main {
    display: grid;
    height: 100%;
}

.App {  
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
}

.App-Body {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 100%;

    background-color: var(--surface-100);

    & > h1 {
        display: block;
        text-align: center;
        font-size: 10rem;
        color: #333;
        margin: auto;
        margin-top: 100px;
        align-content: center;
    }
}
