

.MenuButton {
    color: whitesmoke;
    font-size: 2rem;
    cursor: pointer;

    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;

    box-sizing: border-box;
    transition: all 0.35s ease;

    padding: 0.15em 0;
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    text-decoration: none;
    display: inline-block;


    &::before {
        position: absolute;
        content: '';
        transition: all 0.35s ease;
        opacity: 0;
        top: 25%;
        bottom: 25%;
        left: 0;
        right: 0;
        border-top: 3px solid #34495e;
        border-bottom: 3px solid #34495e;
    }

    &:hover, &.current {
        color: #ffffff;
    }

    &:hover::before, &.current::before {
        opacity: 1;
        top: 0;
        bottom: 0;
    }    
}


.snip1211 {
    font-family: 'Raleway', Arial, sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .snip1211 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }
  .snip1211 li {
    display: inline-block;
    list-style: outside none none;
    margin: 0.5em 1.5em;
    padding: 0;
  }
  .snip1211 a {
    padding: 0.4em 0;
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    text-decoration: none;
    display: inline-block;
  }
  .snip1211 a:before {
    position: absolute;
    content: '';
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
    opacity: 0;
    top: 25%;
    bottom: 25%;
    left: 0;
    right: 0;
    border-top: 3px solid #34495e;
    border-bottom: 3px solid #34495e;
  }
  .snip1211 a:hover,
  .snip1211 .current a {
    color: #ffffff;
  }
  .snip1211 a:hover:before,
  .snip1211 .current a:before {
    opacity: 1;
    top: 0;
    bottom: 0;
  }